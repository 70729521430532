<template>
  <div class="columns mt-3 is-multiline">
    <div class="column is-12">
      <div class="form-container">
        <label class="label-big font-bold has-text-grey-darker">
          Interés ordinario
        </label>
        <div class="columns mt-1">
          <div class="column">
            <ValidSelect
              rules="required"
              label="Tasa base"
              placeholder="Selecciona una tasa base..."
              v-model="interes_ordinario.tasa_base"
              :expanded="true"
              :data="getTasasBase"
              :disabled="arrendamiento || creditoGrupal"
              show="nombre"
              realValue="id"
            />
          </div>
          <div class="column">
            <ValidInput
              :rules="`required|${rule_tasa}`"
              :label="`Tasa ${label_tasa}`"
              placeholder="0.00"
              v-model="interes_ordinario.tasa"
              type="number"
              :expanded="true"
              addon
              addonIcon="percentage"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <ValidSelect
              rules="required"
              label="Tipo de cobro"
              placeholder="Selecciona una opción..."
              v-model="interes_ordinario.tipo_cobro"
              :data="getTratamientoIntereses"
              :disabled="arrendamiento || creditoGrupal"
              show="tipo"
              realValue="id"
              expanded
            />
          </div>
          <div class="column flex items-end pb-5" v-if="pasivo">
            <b-checkbox
              size="is-medium"
              v-model="interes_ordinario.refinanciamiento"
            >
              Refinanciamiento del crédito
            </b-checkbox>
          </div>
          <div class="column flex items-end pb-5">
            <b-checkbox
              size="is-medium"
              v-model="interes_ordinario.iva"
              true-value="1"
              false-value="0"
              :disabled="arrendamiento || creditoGrupal"
            >
              IVA
            </b-checkbox>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <ValidSelect
              rules="required"
              :label="leyendaDiaInhabil"
              placeholder="Selecciona una opción..."
              v-model="interes_ordinario.dia_inhabil"
              :data="getTiposInhabil"
              :disabled="arrendamiento || creditoGrupal"
              show="nombre"
              realValue="id"
              expanded
            />
          </div>
          <div
            v-if="producto_objeto.provision_anticipada == 1 && !credito_activo"
            class="column"
          >
            <ValidInput
              label="Dias de anticipación"
              placeholder="Ingresa la cantidad de dias de anticipación..."
              v-model="interes_ordinario.provision_anticipada"
              type="number"
              expanded
              :disabled="arrendamiento || creditoGrupal"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <ValidSelect
              rules="required"
              label="Fecha de corte"
              placeholder="Selecciona una opción..."
              v-model="interes_ordinario.fecha_corte"
              :data="getFechasCorte"
              :disabled="arrendamiento || creditoGrupal"
              show="nombre"
              realValue="id"
              expanded
            />
          </div>
          <div class="column">
            <ValidSelect
              rules="required"
              label="Forma de cálculo"
              placeholder="Selecciona una opción..."
              v-model="interes_ordinario.forma_calculo"
              :data="getFormasCalculo"
              :disabled="arrendamiento || creditoGrupal"
              show="nombre"
              realValue="id"
              expanded
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import { mapGetters } from "vuex";

export default {
  name: "interes_ordinario",
  props: {
    credito_activo: Boolean,
    pasivo: Boolean,
    interes_ordinario: Object,
    tratamiento_capital: Object,
    producto_objeto: Object,
    dias_antes_provision: Number,
    arrendamiento: {
      type: Boolean,
      default: false
    },
    creditoGrupal: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (this.producto_objeto.provision_anticipada && !this.dias_antes_provision) {
      this.interes_ordinario.provision_anticipada = 0;
    }
    if (this.dias_antes_provision) {
      this.interes_ordinario.provision_anticipada = this.dias_antes_provision;
    }
    if (this.producto_objeto.interes_ordinario.tasa_base_id !== null) {
      this.interes_ordinario.tasa_base = this.producto_objeto.interes_ordinario.tasa_base_id;
    }

    if (this.producto_objeto.iva_interes_ordinario !== null) {
      this.interes_ordinario.iva = this.producto_objeto.iva_interes_ordinario;
    }

    if (this.producto_objeto.interes_ordinario.tasa_base_id == null) {
      this.interes_ordinario.tasa_base = this.getTasasBase.find(
        x => x.nombre == "Tasa Fija"
      ).id;
    }

    if (this.arrendamiento) {
      this.interes_ordinario.dia_inhabil = 0;
      this.interes_ordinario.forma_calculo = 0;
    }

    this.interes_ordinario.tasa = this.producto_objeto.interes_ordinario.cantidad;
  },
  computed: {
    ...mapGetters("catalogos", [
      "getTasasBase",
      "getTratamientoIntereses",
      "getFormasCalculo",
      "getFechasCorte",
      "getTiposInhabil"
    ]),
    leyendaDiaInhabil() {
      if (this.interes_ordinario.tipo_cobro) {
        let tipo_cobro = this.getTratamientoIntereses.find(
          x => x.id == this.interes_ordinario.tipo_cobro
        ).tipo;
        return `En caso de dia inhabil, ¿cual es la mejor opción para ${tipo_cobro}?`;
      }
      return "En caso de dia inhabil, ¿cual es la mejor opción?";
    },
    rule_tasa() {
      let min = '';
      let max = '';
      
      if (this.producto_objeto?.tasa_minimo) {
        min = `min_value:${this.producto_objeto?.tasa_minimo}|`;
      }

      if (this.producto_objeto?.tasa_maximo) {
        max = `max_value:${this.producto_objeto?.tasa_maximo}`;
      }

      return `${min}${max}`;
    },
    label_tasa() {
      let min = '';
      let max = '';

      if (this.producto_objeto?.tasa_minimo) {
        min = `min: ${this.producto_objeto?.tasa_minimo}`;
      }

      if (this.producto_objeto?.tasa_maximo) {
        max = `max: ${this.producto_objeto?.tasa_maximo}`;
      }

      return max || min ? `(${min} , ${max})` : '';
    }
  },
  components: {
    ValidInput,
    ValidSelect
  }
};
</script>
