<template>
  <div class="form-container mt-3">
    <ValidSelect
      rules="required"
      label="¿Cómo es su interés moratorio?"
      fieldClass="label-big"
      placeholder="Selecciona una opción..."
      v-model="interes_moratorio.tipo"
      :data="getFormasCalculoMoratorio"
      show="nombre"
      realValue="id"
      :expanded="true"
    />
    <div class="columns mt-2" v-if="interes_moratorio.tipo == 0">
      <div class="column flex items-end pb-6">
        <b-checkbox
          size="is-medium"
          v-model="interes_moratorio.iva"
          true-value="1"
          false-value="0"
        >
          IVA
        </b-checkbox>
      </div>
      <div class="column">
        <ValidSelect
          rules="required"
          label="Tasa base"
          placeholder="Selecciona una tasa base..."
          v-model="interes_moratorio.tasa_base"
          :expanded="true"
          :data="getTasasBase"
          show="nombre"
          realValue="id"
        />
      </div>
      <div class="column">
        <ValidInput
          rules="required|min_value:0.00"
          label="Tasa"
          placeholder="0.00"
          v-model="interes_moratorio.tasa"
          type="number"
          :expanded="true"
          addon
          addonIcon="percentage"
        />
      </div>
    </div>
    <div class="columns mt-2 is-multiline" v-if="interes_moratorio.tipo == 1">
      <div class="column is-6">
        <b-field label="¿Cuantas veces se multiplicará el interes ordinario?">
          <b-numberinput
            v-model="interes_moratorio.veces"
            step="0.01"
            :type="!pasivo ? 'is-primary' : 'is-pasivo'"
          />
        </b-field>
      </div>
      <div class="column flex items-end pb-5 is-2">
        <b-checkbox
          size="is-medium"
          v-model="interes_moratorio.iva"
          true-value="1"
          false-value="0"
        >
          IVA
        </b-checkbox>
      </div>
      <div
        class="column is-12 flex justify-center items-center text-2xl mt-6 has-text-grey-darker"
        v-if="getTasasBase.length > 0"
      >
        <span class="font-bold text-5xl mr-2">{{
          interes_moratorio.veces
        }}</span>
        <fa-icon icon="times" size="1x" class="mr-2 mt-1" />
        <span class="font-medium flex items-end text-6xl -mt-2">[</span>
        <span class="font-medium text-sm w-1/5 text-center">
          <b-tooltip
            label="Tasa base"
            :type="!pasivo ? 'is-primary' : 'is-pasivo'"
          >
            {{
              getTasasBase.find(x => x.id == interes_ordinario.tasa_base).nombre
            }}
          </b-tooltip>
        </span>
        <fa-icon icon="plus" size="1x" class="mx-2 mt-1" />
        <div class="font-medium mr-4 flex items-center text-5xl">
          <b-tooltip label="Tasa" :type="!pasivo ? 'is-primary' : 'is-pasivo'">
            {{ interes_ordinario.tasa }}%
          </b-tooltip>
          <!-- <fa-icon icon="percentage" size="1x" class="ml-2" /> -->
        </div>
        <span class="font-medium flex items-end text-6xl mr-3 -mt-2">]</span>
        <div
          v-if="
            interes_ordinario.tasa_base ==
              getTasasBase.find(x => x.nombre == 'Tasa Fija').id
          "
          class="flex items-center"
        >
          <fa-icon icon="equals" class="mr-2" size="2x" />
          <span class="font-medium text-6xl">
            {{ (interes_ordinario.tasa * interes_moratorio.veces).toFixed(2) }}
          </span>
          <fa-icon icon="percentage" size="2x" class="ml-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import { mapGetters } from "vuex";

export default {
  name: "interes_moratorio",
  props: {
    pasivo: Boolean,
    interes_ordinario: Object,
    interes_moratorio: Object,
    producto_objeto: Object
  },
  mounted() {
    if (this.producto_objeto.iva_interes_ordinario !== null) {
      this.interes_moratorio.iva = this.producto_objeto.iva_interes_moratorio;
    }
    if (this.producto_objeto.factor_moratorio > 0) {
      this.interes_moratorio.tipo = 1;
      this.interes_moratorio.veces = this.producto_objeto.factor_moratorio;
    } else {
      this.interes_moratorio.tipo = 0;
      this.interes_moratorio.tasa = this.producto_objeto.interes_moratorio.cantidad;
      
      if (this.getTasasBase) {
        this.interes_moratorio.tasa_base = this.getTasasBase.find(tasa => tasa.nombre == 'Tasa Fija')?.id;
      }
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getTasasBase", "getFormasCalculoMoratorio"])
  },
  components: {
    ValidInput,
    ValidSelect
  }
};
</script>
