<template>
  <div
    class="columns is-multiline relative has-background-primary-04 py-16 md:py-0 main-cols"
    :class="{ 'has-background-pasivo-04': pasivo }"
  >
  <div class="container">
    <div class="column is-12 has-border-radius">
      <b-image
        v-if="pasivo"
        src="/images/disposicion-hero-pasiva.svg"
        alt="placeholder solicitud disposicion pasiva"
      />
      <b-image v-else src="/images/disposicion-hero.svg" alt="placeholder solicitud disposicion activa" />
    </div>
    <div
      class="column is-12 has-border-radius relative"
    >
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <p class="title has-text-grey-darker">{{ titulo }}</p>
          <p>
            Vas a empezar una nueva experiencia para solicitar, seguir y
            entregar una disposición, todo centrado en que sea lo más práctico,
            entendible y eficiente posible. No olvides mandarnos tus comentarios
            en la página de ayuda.
          </p>
          <b-button
            :type="!pasivo ? 'is-primary' : 'is-pasivo'"
            @click="closeWelcome()"
            class="w-3/5 mt-3"
            >Empezar</b-button
          >
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "bienvenido",
  props: {
    pasivo: Boolean
  },
  methods: {
    closeWelcome() {
      this.$emit("closeWelcome");
    }
  },
  computed: {
    titulo() {
      if (this.$route.name == "fondear_disposicion") {
        return "Fondea la disposición";
      }
      if (this.$route.name == "editar_disposicion") {
        return "Editar disposición";
      }
      return `Solicita una disposición ${this.pasivo ? "pasiva" : "activa"}`;
    }
  }
};
</script>

<style lang="scss">
.img-container-absolute {
  position: relative;
  overflow: hidden;
  height: 60vh;
  .img-absolute {
    position: absolute;
    top: -13%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &.pasivo {
      top: 0;
    }
  }
  @media only screen and (max-width: 1300px) {
    height: 22rem;
  }
}
</style>
