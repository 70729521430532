<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Datos extras</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <p class="mb-3">
          Antes de continuar con la solicitud, es necesario llenar los
          siguientes datos:
        </p>
        <div class="columns is-multiline">
          <div class="column is-12">
            <valid-select
              rules="required"
              label="Destino de los recursos"
              placeholder="Selecciona una opción..."
              v-model="form.destino_recursos"
              :expanded="true"
              :data="getDestinoRecursos"
              show="nombre"
              realValue="id"
            />
            <valid-select
              rules="required"
              label="Estado"
              placeholder="Selecciona uno..."
              v-model="estado"
              fieldClass="has-text-grey-darker"
              :data="getEstados"
              show="nombre"
              :expanded="true"
              realValue="id"
              @input="loadMunicipios"
            />
            <valid-auto-complete
              rules="required"
              label="Municipio"
              v-if="getMunicipios"
              placeholder="Selecciona uno..."
              v-model="municipio"
              fieldClass="has-text-grey-darker"
              :data="getMunicipios"
              show="nombre"
              :expanded="true"
              realValue="id"
              @select="loadLocalidades"
            />
            <valid-auto-complete
              label="Localidad"
              v-if="getLocalidades"
              placeholder="Selecciona uno..."
              v-model="localidad"
              fieldClass="has-text-grey-darker"
              :data="getLocalidades"
              show="nombre"
              :expanded="true"
              realValue="id"
              @select="setLocalidad"
            />
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          :type="
            !pasivo 
              ? arrendamiento ? 'is-arrendamiento' : 'is-primary' 
              : 'is-pasivo'
          "
          @click="validate().then(result => setData(result))"
        >
          {{ crearText }}
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import ValidSelect from "@/components/form/ValidSelect";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";

export default {
  name: "ModalDestinoLocalidad",
  props: {
    linea: Object,
    disposicion: [Object, null],
    pasivo: Boolean,
    arrendamiento: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        persona_id: null,
        id: null,
        destino_recursos: null,
        localidad_id: null
      },
      estado: null,
      municipio: null,
      localidad: null
    };
  },
  components: {
    ValidationObserver,
    ValidSelect,
    ValidAutoComplete
  },
  computed: {
    ...mapGetters("catalogos", [
      "getTasasBase",
      "getDestinoRecursos",
      "getEstados",
      "getMunicipios",
      "getLocalidades"
    ]),
    financiando() {
      return this.$route.name == "financiar_cuenta" ? true : false;
    },
    crearText() {
      switch (this.$route.name) {
        case "fondear_disposicion":
          return "Fondear disposición";
        case "financiar_cuenta":
          return "Financiar cuenta";
        case "solicitar_arrendamiento":
          return "Solicitar arrendamiento";
        default:
          return "Solicitar disposición";
      }
    }
  },
  methods: {
    setData(res) {
      if (!res) return;
      this.$emit("continuar", this.form);
      this.$parent.close();
    },
    loadMunicipios() {
      this.$store.dispatch("catalogos/getMunicipios", this.estado);
    },
    loadLocalidades(val) {
      this.$store.dispatch("catalogos/getLocalidades", val.id);
    },
    setLocalidad(val) {
      this.form.localidad_id = val.id;
    }
  },
  created() {
    this.form.persona_id = this.linea.persona_id;
    this.form.id = this.linea.id;
    this.form.destino_recursos = this.linea.destino_recursos;
    this.form.localidad_id = this.linea.localidad_id;
    if (this.getDestinoRecursos == null) {
      this.$store.dispatch("catalogos/getDestinoRecursos");
    }
    if (this.getEstados == null) {
      this.$store.dispatch("catalogos/getEstados");
    }
    if (this.disposicion && this.disposicion.localidad) {
      this.$store.dispatch(
        "catalogos/getMunicipios",
        this.disposicion.localidad.estado_id
      );
      this.$store.dispatch(
        "catalogos/getLocalidades",
        this.disposicion.localidad.municipio_id
      );
      this.estado = this.disposicion.localidad.estado_id;
      this.municipio = this.disposicion.localidad.municipio.nombre;
      this.form.localidad_id = this.disposicion.localidad.id;
      this.localidad = this.disposicion.localidad.nombre;
    }
  }
};
</script>
