<template>
  <div class="form-container mt-3">
    <label class="label-big font-bold has-text-grey-darker mb-2">
      Agrega accesorios al disponer el crédito ({{ moneda }})
    </label>
    <div class="columns mt-2">
      <div class="column is-3">
        <ValidSelect
          rules=""
          label="Accesorio"
          placeholder="Selecciona un accesorio..."
          v-model="data.nombre"
          :expanded="true"
          :data="getCostosExtras"
          show="nombre"
          realValue="id"
        />
      </div>
      <div class="column is-3">
        <ValidCurrencyInput
          rules="currency"
          v-if="data.tipo == 'Monto'"
          label="Monto"
          placeholder="0.00"
          v-model="data.monto"
          :expanded="true"
          addon
          addonIcon="dollar-sign"
        />
        <ValidInput
          rules="required|min_value:0.00"
          v-if="data.tipo == 'Porcentaje'"
          :label="labelMonto"
          placeholder="1"
          v-model="data.monto"
          type="number"
          expanded
          @input="montoAccesorio"
          addon
          addonIcon="percentage"
        />
      </div>
      <div class="column is-4">
        <valid-datepicker
          rules=""
          label="Fecha de vencimiento"
          placeholder="selecciona una fecha..."
          v-model="data.fecha_vencimiento"
        />
      </div>
      <div class="column text-center is-2">
        <label for="ivacheckbox" class="label">IVA</label>
        <b-checkbox
          id="ivacheckbox"
          class="ml-2"
          size="is-medium"
          v-model="data.iva"
          :type="!pasivo ? 'is-primary' : 'is-pasivo'"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <b-button
          :type="!pasivo ? 'is-primary' : 'is-pasivo'"
          expanded
          outlined
          @click="addAccesorio()"
        >
          Agregar accesorio
        </b-button>
      </div>
    </div>
    <div v-if="accesorios.detalles.length > 0">
      <p class="subtitle font-light text-2xl has-text-grey-darker">
        Accesorios agregados
      </p>
      <b-table
        :data="accesorios.detalles"
        class="has-border-radius mb-6 has-background-white-ter"
      >
        <b-table-column field="accesorio" label="ACCESORIO" v-slot="props">
          {{
            getCostosExtras.find(x => x.id == props.row.nombre)
              ? getCostosExtras.find(x => x.id == props.row.nombre).nombre
              : ""
          }}
        </b-table-column>
        <b-table-column field="valor" label="VALOR" v-slot="props">
          <span
            v-if="
              $route.name == 'editar_disposicion' && props.row.tipo == 'Monto'
            "
          >
            {{ props.row.monto | currency | hp }}
          </span>
          <span v-else>
            {{ props.row.monto }}{{ props.row.tipo == "Porcentaje" ? "%" : "" }}
          </span>
          <span v-if="props.row.monto_porcentaje">
            ({{ props.row.monto_porcentaje | currency | hp }})
          </span>
        </b-table-column>
        <b-table-column
          field="fecha_vencimiento"
          label="FECHA VENCIMIENTO"
          v-slot="props"
        >
          {{ props.row.fecha_vencimiento | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column field="iva" label="IVA" v-slot="props">
          {{ props.row.iva ? "Sí" : "No" }}
        </b-table-column>
        <b-table-column field="btn_options" label="" v-slot="props">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            class="cursor-pointer"
          >
            <fa-icon icon="ellipsis-v" class="mr-4" slot="trigger" />
            <b-dropdown-item
              aria-role="listitem"
              @click="deleteRow(accesorios.detalles, props.index)"
            >
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import { mapGetters } from "vuex";

export default {
  name: "accesorios_page",
  props: {
    pasivo: Boolean,
    accesorios: Object,
    producto_monto: Object
  },
  data() {
    return {
      data: {
        nombre: null,
        monto: null,
        iva: false,
        fecha_vencimiento: this.$fecha_cierre,
        tipo: "Monto"
      },
      valor_capital: null
    };
  },
  mounted() {
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getCostosExtras", "getMonedas"]),
    sumaAccesorios() {
      if (this.accesorios.detalles.length < 1) {
        return 0;
      }

      return this.accesorios.detalles.reduce((acc, accesorio) => {
        return accesorio.tipo == "Porcentaje"
          ? acc + this.producto_monto.monto * (accesorio.monto / 100)
          : acc + accesorio.monto;
      }, 0);
    },
    accesorio() {
      if (this.data.nombre) {
        return this.data.nombre;
      }
      return null;
    },
    labelMonto() {
      if (this.valor_capital == null) {
        return "Porcentaje";
      } else {
        return `Porcentaje (${this.$options.filters.currency(
          this.valor_capital
        )})`;
      }
    },
    moneda() {
      return this.getMonedas.find(x => x.id == this.producto_monto.moneda)
        .clave;
    },
    editando() {
      if (
        this.data.nombre != null ||
        this.data.monto != null ||
        this.data.fecha_vencimiento != null
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    ValidInput,
    ValidSelect,
    ValidCurrencyInput,
    ValidDatepicker
  },
  methods: {
    // calcula el monto del accesorio en base al porcentaje
    montoAccesorio() {
      this.valor_capital = this.producto_monto.monto * (this.data.monto / 100);
    },
    // agrega el accesorio al array de accesorios.detalles
    addAccesorio() {
      if (!Object.values(this.data).includes(null)) {
        let repetido = this.accesorios.detalles.find(
          x => x.nombre == this.data.nombre
        );
        if (!repetido) {
          const monto =
            this.data.tipo == "Monto"
              ? this.data.monto + this.sumaAccesorios
              : this.valor_capital + this.sumaAccesorios;
          if (monto > this.producto_monto.monto) {
            return this.notification(
              "warning",
              "Cantidad sobrepasa el capital de la disposición",
              `La suma de los accesorios es mayor a el capital de la disposición.`
            );
          }

          if (this.data.tipo == "Monto") {
            this.accesorios.detalles.push({
              nombre: this.data.nombre,
              monto: this.data.monto,
              iva: this.data.iva,
              tipo: this.data.tipo,
              fecha_vencimiento: this.data.fecha_vencimiento
            });
          } else if (this.data.tipo == "Porcentaje") {
            let valor = this.producto_monto.monto * (this.data.monto / 100);
            this.accesorios.detalles.push({
              nombre: this.data.nombre,
              monto: this.data.monto,
              iva: this.data.iva,
              tipo: this.data.tipo,
              monto_porcentaje: valor,
              fecha_vencimiento: this.data.fecha_vencimiento
            });
          }
          this.data.nombre = null;
          this.data.monto = null;
          this.data.iva = false;
          this.data.fecha_vencimiento = null;
          this.data.tipo = "Monto";
          this.valor_capital = 0;
        } else {
          this.notification(
            "warning",
            "Accesorio previamente agregado",
            `El accesorio que deseas agregar ya existe en la lista de accesorios agregados`
          );
        }
      } else {
        this.notification(
          "warning",
          "Campos vacios",
          `Debes llenar todos los campos para agregar el accesorio`
        );
      }
    },
    /*
      elimina un accesorio
      @array (Array): arreglo de accesorios,
      @index (Integer): posición del arreglo a eliminar
    */
    deleteRow(array, index) {
      array.splice(index, 1);
    }
  },
  watch: {
    accesorio: function(val) {
      this.data.monto = null;
      if (val) {
        let accesorio_obj = this.getCostosExtras.find(x => x.id == val);
        if (
          accesorio_obj.tipo_pago &&
          accesorio_obj.tipo_pago.nombre == "Monto"
        ) {
          this.data.tipo = "Monto";
        } else {
          this.data.tipo = "Porcentaje";
        }
      }
    },
    editando: function(val) {
      this.accesorios.editando = val;
    }
  }
};
</script>
